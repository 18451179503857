import axiosWarehouse from '@/libs/axios-warehouse'

const getAxios = () => axiosWarehouse.get('/brands')
const postAxios = data => axiosWarehouse.post('/brands', data)
const oneAxios = id => axiosWarehouse.get(`/brands/${id}`)
const putAxios = (id, data) => axiosWarehouse.put(`/brands/${id}`, data)
const deleteAxios = id => axiosWarehouse.delete(`/brands/${id}`)

export default {
  getAxios,
  postAxios,
  oneAxios,
  putAxios,
  deleteAxios,
}
